import React, { useState, useEffect } from 'react';
import "./Footer.css";
    

const Footer = () => {
  
  return (

    <div className='footer'>
      <p className='ft'>Contact dasom_2024@naver.com</p>
      <p className='ft'>___</p>
      <div className='copy'>
      <p className='ft'>© 2024 Dasom. ALL RIGHTS RESERVED.</p>
      </div>
      
    </div>
  );
};

export default Footer;


